/* src/components/Footer/Footer.module.css */

/* CSS Variables for Consistent Styling */
:root {
  --color-bg: #f5f5f5; /* Background color */
  --color-text: #333; /* Main text color */
  --color-gray: #a0a0a0; /* Secondary text color */
  --color-icon: #444; /* Icon color */
  --color-hover: #d3072b; /* Hover color for icons and links */
}

/* Root Footer Styles */
.footer {
  width: 100%;
  background-color: var(--color-bg);
  padding: 2rem 1rem;
  font-family: "Poppins", sans-serif;
  color: var(--color-text);
  line-height: 1.5;
}

/* Inner Container */
.innerContainer {
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Left Column */
.leftColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensure full width on mobile */
}

.mainLogo {
  width: 150px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 1rem;
}

.socialLinks {
  display: flex;
  gap: 1rem;
}

.socialLink {
  color: var(--color-icon);
  transition: color 0.3s;
}

.socialIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.socialLink:hover .socialIcon {
  color: var(--color-hover);
}

/* Middle Column */
.middleColumn {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  /* Center align on mobile */
  align-items: center;
  text-align: center;

  width: 100%; /* Ensure full width on mobile */
}

/* Right Column */
.rightColumn {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  /* Center align on mobile */
  align-items: center;
  text-align: center;

  width: 100%; /* Ensure full width on mobile */
}

/* Info Sections */
.infoSection {
  display: flex;
  flex-direction: column;

  /* Ensure uniform width on mobile */
  width: 100%;
  max-width: none; /* Remove any existing max-width constraints */

  /* Consistent padding */
  padding: 0 1rem;

  /* Center align text */
  text-align: center;
}

/* Section Titles */
.sectionTitle {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

/* Links */
.link {
  color: var(--color-text);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Reduce margin between p elements in infoSection */
.infoSection p {
  margin: 0.25rem 0; /* Adjust the value as needed */

  /* Ensure text wraps properly */
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
}

.infoSection a {
  word-wrap: break-word;
  word-break: break-all;
}

/* Copy Right */
.copyRight {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.75rem;
  color: var(--color-gray);
}

/* Desktop Styles */
@media (min-width: 768px) {
  .innerContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .leftColumn,
  .middleColumn,
  .rightColumn {
    flex: 1;
    width: auto; /* Reset width to auto for desktop */
  }

  .leftColumn {
    align-items: center;
  }

  .middleColumn,
  .rightColumn {
    align-items: flex-start;
    text-align: left; /* Override text alignment for desktop */
  }

  .infoSection {
    padding: 0; /* Remove horizontal padding for desktop */
    text-align: left; /* Align text to left on desktop */
  }

  .copyRight {
    text-align: center;
    font-size: 0.85rem;
  }
  .infoSection p {
    text-align: left;
  }
}
